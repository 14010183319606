fast-image-cropper {
    position: fixed;
    z-index: 2000;
}

.w-input {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.w-input > .help {
    color: var(--color-neutral-700);
    font-size: 14px;
}

.w-input > .error {
    color: var(--color-destructive-700);
    font-size: 14px;
}

.w-image-picker {
    display: grid;
    gap: var(--space-lg);
}

.w-image-picker > .input {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.w-image-picker > .input > .input-actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
}

/* === SCREEN (LG) === */
@media (min-width: 1024px) {
    .w-image-picker {
        grid-template-columns: 1fr 2fr;
    }
    
    .w-image-picker > .input {
        align-items: start;
    }
}